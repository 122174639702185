<script setup>
import MyBeesScreen from '../components/screens/MyBeesScreen.vue';
import { onMounted } from 'vue';
import { changeBackgroundUrl } from '@/utils/view';

onMounted(() => {
	changeBackgroundUrl('backMyBees.png');
});
</script>

<template>
	<MyBeesScreen />
</template>
